import { Link } from "react-router-dom";
import "./TestNav.css";

const TestNav = () => (
  <div className="test-nav">
    <Link to="/">Home</Link>
    <Link to="/example">example</Link>
    <Link to="/select_country">国選択</Link>
    <Link to="/select_date">日程選択</Link>
    <Link to="/products">商品詳細</Link>
    <Link to="/select_category">カテゴリー選択</Link>
    <Link to="/compare_product_prices">料金比較</Link>
  </div>
);
export default TestNav;
