import Chart from "react-google-charts";
import { useState, useEffect } from "react";

import "./PageCountrySelect.css";

/**
 * 国データを記述したJSONをimport
 */
import countries from "./data/regions.json";
import countryNameJa from "./data/regions_ja.json";

/**
 *
 */
const getFlatCountryCodes = (area) => {
  return area.regions
    .map((reg) => {
      return reg.country_codes;
    })
    .flat();
};

/**
 * 国をタッチで選択可能にする
 */
const GeoChart = (props) => {
  const options = {
    intervals: { style: "sticks" },
    legend: "none",
    is3D: true,
    tooltip: {
      trigger: "none",
    },
    datalessRegionColor: "rgb(218,217,241)",
    defaultColor: "rgb(111,108,203)",
    colorAxis: { minValue: 0, colors: ["#000000", "rgb(111,108,203)"] },
  };

  return (
    <Chart
      width={"100vw"}
      height={"60vw"}
      chartType="GeoChart"
      className="geochart"
      loader={<div>Loading...</div>}
      data={[["", ""], ...props.regionData]}
      options={
        props.areaCode !== ""
          ? { ...options, region: props.areaCode }
          : { ...options }
      }
      mapsApiKey="AIzaSyD-9tSrke72PouQMnMX-a7eZSW0jkFMBWY"
      chartEvents={[
        {
          eventName: "ready",
          callback: ({ chartWrapper, google }) => {
            const chart = chartWrapper.getChart();
            google.visualization.events.addListener(
              chart,
              "regionClick",
              // チャートクリック時に、クリックされたregionを自動的に拡大する
              (e) => {
                console.log(props);
                console.log(999, e.region, props.phase);
                if (props.phase === 1) {
                  // 渡ってきた国コードから、エリアコードを取得して比較
                  const area = countries.find((area) =>
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    area.regions.find(({ country_codes }) =>
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      country_codes.find((code) => e.region === code)
                    )
                  );

                  const areaCodes = getFlatCountryCodes(area);

                  props.setCodes(areaCodes);
                  props.setRegions(area.regions);
                  props.setAreaCode(area.area_code);
                  props.setBcList([
                    ...props.item.filter((v) => {
                      return v !== props.item[props.item.length - 1];
                    }),
                    area.area_name_ja,
                  ]);

                  props.setPhase(2);
                } else if (props.phase === 2) {
                  const region = countries
                    .map((area) =>
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      area.regions.find(({ country_codes }) =>
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        country_codes.find((code) => e.region === code)
                      )
                    )
                    .find((c) => c);

                  // 渡された国コードを含むregionを抽出
                  props.setCodes(region.country_codes);
                  props.setAreaCode(region.region_code);
                  props.setPhase(3);
                  props.setBcList([...props.item, region.region_name_ja]);
                  return false;
                } else if (e.region.length === 3) {
                  console.log(e.region);
                  const area = countries.find((v) => {
                    return v.area_code === e.region;
                  });

                  props.setRegions(area.regions);
                  props.setCodes(getFlatCountryCodes(area));
                  props.setAreaCode(e.region);
                  props.setPhase(2);
                  props.setBcList([
                    ...props.item.filter((v) => {
                      return v !== props.item[props.item.length - 1];
                    }),
                    area.area_name_ja,
                  ]);
                  return false;
                }

                if (props.phase === 3 /* && e.region.length === 2 */) {
                  props.history.push("/example");
                }
              }
            );
          },
        },
      ]}
    />
  );
};

const BreadCrumbList = (props) => (
  <ul className="bclist">
    <li
      onClick={() => {
        props.setPhase(props.phase - 1);
        props.setBcList(
          props.item.filter((v) => {
            return v !== props.item[props.item.length - 1];
          })
        );
      }}
      className="bclist-item bclist-back"
    >
      戻る
    </li>
    {props.item.map((v) => {
      return v === props.item[props.item.length - 1] ? (
        <li key={v} className="bclist-item selected">
          {v}
        </li>
      ) : (
        <li
          key={v}
          onClick={() => {
            props.setPhase(props.phase - 1);
            props.setBcList(
              props.item.filter((v) => {
                return v !== props.item[props.item.length - 1];
              })
            );
          }}
          className="bclist-item"
        >
          {v}
        </li>
      );
    })}
  </ul>
);

/**
 * 国データの日本語表示
 *
 * backボタンを押した時に、選択状態を変えるようにする
 */
const PageCountrySelect = (props) => {
  // 全世界データの設定
  const [areas, setAreas] = useState(countries);

  // Area(Americas, Asia)データの設定
  const [regions, setRegions] = useState([]);
  const [phase, setPhase] = useState(1);
  const [codes, setCodes] = useState([]);
  const [areaCode, setAreaCode] = useState("");
  const [bcList, setBcList] = useState([]);

  return (
    <div className="container">
      <h1>
        <i className="ion ionicons icon ion-ios-arrow-back"></i>地域の選択
      </h1>
      <GeoChart
        regionData={codes.map((v) => [v, 100])}
        codes={codes}
        areaCode={areaCode}
        setCodes={setCodes}
        setAreaCode={setAreaCode}
        setRegions={setRegions}
        setPhase={setPhase}
        phase={phase}
        setBcList={setBcList}
        item={bcList}
        history={props.history}
      />

      <ul className="region-select">
        {phase === 1 && (
          <>
            {/* <BreadCrumbList item={bcList} setPhase={setPhase} phase={phase} /> */}
            {areas.map((area) => {
              return (
                <li
                  key={area.area_code}
                  onClick={() => {
                    setCodes(getFlatCountryCodes(area));
                    setRegions(area.regions);
                    setAreaCode(area.area_code);
                    setPhase(2);
                    setBcList([...bcList, area.area_name_ja]);
                  }}
                >
                  {area.area_name_ja}
                </li>
              );
            })}
          </>
        )}

        {phase === 2 && (
          <>
            <BreadCrumbList
              item={bcList}
              setPhase={setPhase}
              phase={phase}
              setBcList={setBcList}
            />
            {regions.map((region) => {
              return (
                <li
                  onClick={() => {
                    setCodes(region.country_codes);
                    setAreaCode(region.region_code);
                    setPhase(3);
                    setBcList([...bcList, region.region_name_ja]);
                  }}
                  key={region.region_code}
                >
                  {region.region_name_ja}
                </li>
              );
            })}
          </>
        )}

        {phase === 3 && (
          <>
            <BreadCrumbList
              item={bcList}
              setPhase={setPhase}
              phase={phase}
              setBcList={setBcList}
            />
            {codes.map((code) => {
              return (
                <li
                  onClick={() => {
                    props.history.push("/select_date/" + code);
                  }}
                  key={code}
                >
                  {countryNameJa[code]}
                </li>
              );
            })}
          </>
        )}
      </ul>
    </div>
  );
};

export default PageCountrySelect;
