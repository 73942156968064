import { Route, Router } from "react-router-dom";
import history from "appHistory";
import TestNav from "./organisms/TestNav";
import PageTop from "./pages/PageTop";
import PageExample from "./pages/PageExample";
import PageCountrySelect from "./pages/PageCountrySelect";
import PageDateSelect from "./pages/PageDateSelect";
import PageProductDetail from "./pages/PageProductDetail";
import PageCategorySelect from "./pages/PageCategorySelect";
import PageCompareProductPrices from "./pages/CompareProductPrices";
import { Provider } from "react-redux";
import store from "store";
import { CalendarProvider } from "./organisms/Calendar";

const App = () => (
  <Provider store={store}>
    <CalendarProvider>
      <Router history={history}>
        <React.Fragment>
          <TestNav />
          <div>
            <Route exact path="/" component={PageTop} />
            <Route exact path="/example" component={PageExample} />
            <Route exact path="/select_country" component={PageCountrySelect} />
            <Route
              exact
              path="/select_date/:country_code"
              component={PageDateSelect}
            />
            <Route
              exact
              path="/select_category"
              component={PageCategorySelect}
            />
            <Route
              exact
              path="/compare_product_prices"
              component={PageCompareProductPrices}
            />
            <Route
              exact
              path="/products/:product_id"
              component={PageProductDetail}
            />
          </div>
        </React.Fragment>
      </Router>
    </CalendarProvider>
  </Provider>
);
export default App;
