import "./CompareProductPrices.css";
const PageCompareProductPrices = () => (
  <div className="page-compare-product-prices">
    <h1>
      <i className="ion ionicons icon ion-ios-arrow-back"></i>旅行用品 値段比較
    </h1>
    <div className="pd_product-head">
      <img src="sc.jpg" />
      <div className="name">スーツケース(M)</div>
    </div>
    <hr />
    <ul className="pd_list">
      <li>
        <img src="sc.jpg" />
        <div className="name">
          リモワ 軽量・4輪・TSAロック搭載 スーツケース サルサエアー
          マルチホイール 80L ウルトラバイオレット
        </div>
        <div className="desc">
          <span className="price">4,430</span>
          <span className="tag-r">レンタル</span>
        </div>
        <div className="sitename">スーパーレンタルショップ ダーリング</div>
      </li>
      <li>
        <img src="sc2.jpg" />
        <div className="name">
          アメリカンツーリスター 軽量・4輪・TSAロック搭載 スーツケース Lightrax
          34L ターコイズ
        </div>
        <div className="desc">
          <span className="price">9,980</span>
          <span className="tag-b">購入</span>
        </div>
        <div className="sitename">ビックカメラ.com</div>
      </li>
      <li>
        <img src="sc3.jpg" />
        <div className="name">
          ace. 軽量・4輪・ワイヤー式ロック搭載 スーツケース リップルｚ
          ダブルホイール 93L ブラック
        </div>
        <div className="desc">
          <span className="price">4,410</span>
          <span className="tag-r">レンタル</span>
        </div>
        <div className="sitename">スーパーレンタルショップ ダーリング</div>
      </li>
      <li>
        <img src="sc6.jpg" />
        <div className="name">
          A.L.Iアジア・ラゲージ 軽量・4輪・TSAロック搭載 スーツケース RUNWAY
          マルチホイール 40L ネイビー
        </div>
        <div className="desc">
          <span className="price">4,260</span>
          <span className="tag-r">レンタル</span>
        </div>
        <div className="sitename">amazon</div>
      </li>
      <li>
        <img src="sc4.jpg" />
        <div className="name">
          TABITORA 大型・4輪・TSAロック搭載 スーツケース TABITORA 60180
          ダブルホイール 80L シルバー
        </div>
        <div className="desc">
          <span className="price">10,480</span>
          <span className="tag-b">購入</span>
        </div>
        <div className="sitename">amazon</div>
      </li>
      <li>
        <img src="sc5.jpg" />
        <div className="name">
          ace. 軽量・4輪・ワイヤー式ロック搭載 スーツケース リップルｚ
          ダブルホイール 93L ブラック
        </div>
        <div className="desc">
          <span className="price">4,260</span>
          <span className="tag-r">レンタル</span>
        </div>
        <div className="sitename">スーパーレンタルショップ ダーリング</div>
      </li>
    </ul>
  </div>
);
export default PageCompareProductPrices;
