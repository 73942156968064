import "./PageProductDetail.css";
import { useState, useEffect } from "react";
import axios from "axios";

const PageProductDetail = (props) => {
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const param = props.history.location.pathname.split("/").pop();
      const result = await axios.get(
        "https://tabi-goods.com/api/product/" + param
      );

      setData(result.data);
    };

    fetchData();
  }, []);

  return (
    <div className="page-product-deatil">
      <h1>
        <i className="ion ionicons icon ion-ios-arrow-back"></i>商品詳細
      </h1>
      <div key={data.id}>
        <img src={data.image} />
        <div className="deatail-list">
          <div className="name">{data.name}</div>
          <div className="price">￥{data.price}</div>
        </div>
        <div className="description">
          <div className="description-title">商品説明</div>
          <div className="description-article">{data.description}</div>
        </div>
        <div className="transition">
          <div className="transition-button">
            <i className="ion ionicons icon ion-ios-arrow-forward"></i>
            <p>ショップの売り場へ行く</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageProductDetail;
