import React, { useMemo, useState, useContext, useCallback } from "react";
import "./Calendar.css";

const CalendarContext = React.createContext({});
const UNIXTIME_1DAY = 1000 * 60 * 60 * 24;
const UNIXTIME_JST = 1000 * 60 * 60 * 9;
export const getYMD = (d) => Math.floor((d - 0 + UNIXTIME_JST) / UNIXTIME_1DAY);
export const ymd2ymdText = (ymd) =>
  new Date(ymd * UNIXTIME_1DAY).toISOString().slice(0, 10);
export const useCalendar = () => useContext(CalendarContext);
export const CalendarProvider = ({ children }) => {
  const [state, setState] = useState(() => {
    const now = new Date();
    return {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      _cmode: false,
      startDate: new Date(0),
      endDate: new Date(0),
      startYMD: 0,
      endYMD: 0,
      selecting: false,
    };
  });
  const actions = useMemo(
    () => ({
      switchToNextMonth() {
        setState((state) => {
          const date = new Date();
          date.setFullYear(state.year);
          date.setMonth(state.month);
          return {
            ...state,
            year: date.getFullYear(),
            month: date.getMonth() + 1,
          };
        });
      },
      switchToPrevMonth() {
        setState((state) => {
          const date = new Date();
          date.setFullYear(state.year);
          date.setMonth(state.month);
          return {
            ...state,
            year: date.getFullYear(),
            month: date.getMonth() - 1,
          };
        });
      },
      onDateClick(ymd) {
        setState((state) => {
          let {
            _cmode,
            startDate,
            startYMD,
            endDate,
            endYMD,
            selecting,
          } = state;
          if (_cmode) {
            if (startYMD >= ymd) {
              endYMD = startYMD;
              endDate = startDate;
              startYMD = ymd;
              startDate = new Date(UNIXTIME_1DAY * ymd);
            } else {
              endYMD = ymd;
              endDate = new Date(UNIXTIME_1DAY * ymd);
            }
            selecting = false;
          } else {
            startYMD = endYMD = ymd;
            startDate = endDate = new Date(UNIXTIME_1DAY * ymd);
            selecting = true;
          }
          return {
            ...state,
            _cmode: !_cmode,
            startDate,
            startYMD,
            endDate,
            endYMD,
            selecting,
          };
        });
      },
    }),
    []
  );
  const { year, month, startYMD, endYMD, selecting } = state;
  const { onDateClick } = actions;
  const realMonth = month - 1;
  const dates = useMemo(() => {
    const date = new Date();
    date.setFullYear(year);
    date.setMonth(realMonth);
    date.setDate(1);
    date.setDate(1 - date.getDay());
    const r = [];
    for (let w = 5; w--; ) {
      let week;
      r.push((week = []));
      for (let wd = 0; wd < 7; wd++) {
        const ymd = getYMD(date);
        const onClick = () => {
          onDateClick(ymd);
        };
        week.push({
          date: date.getDate(),
          ymd,
          onClick,
          isThisMonth: date.getMonth() === realMonth,
          inRange: startYMD <= ymd && ymd <= endYMD,
          isStart: startYMD === ymd,
          isEnd: endYMD === ymd,
        });
        date.setDate(date.getDate() + 1);
      }
    }
    return r;
  }, [year, month, startYMD, endYMD]);
  return (
    <CalendarContext.Provider value={{ ...state, ...actions, dates }}>
      {children}
    </CalendarContext.Provider>
  );
};
