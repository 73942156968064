import { connect } from "react-redux";
import { counter } from "store/actions";
import { useCallback } from "react";

const Count = ({ count }) => <span>【{count}】</span>;
const CountContainer = connect(({ counter: { count } }) => ({ count }))(Count);

const IncrementButton = () => {
  const increment = useCallback(() => counter.increment());
  return <button onClick={increment}>＋＋</button>;
};
const DecrementButton = () => {
  const decrement = useCallback(() => counter.decrement());
  return <button onClick={decrement}>－－</button>;
};

const PageExample = () => (
  <div className="page-example">
    <h1>example page</h1>
    <p>
      <DecrementButton />
      <IncrementButton />
    </p>
    <p>
      ConnectedCount: <CountContainer />
    </p>
    <p>
      Count(3): <Count count={3} />
    </p>
  </div>
);
export default PageExample;
