import "./PageTop.css";
const PageTop = () => (
  <div className="container">
    <div className="pagetop-mainimage"></div>

    <div className="app-start-button">Let;&apos;s Start</div>

    <div className="app-feature-title">旅グッズの特徴</div>
    <div className="app-feature-box">
      <div className="app-description">
        <div className="app-description-title">行き先と日程を選ぶ</div>
        <div className="app-description-image">特徴1の画像</div>
      </div>
      <div className="app-description">
        <div className="app-description-title">最適な旅行グッズを提案</div>
        <div className="app-description-image">特徴2の画像</div>
      </div>
      <div className="app-description">
        <div className="app-description-title">価格をその場で簡単比較</div>
        <div className="app-description-image">特徴3の画像</div>
      </div>
    </div>
    <div className="app-start-button">Let;&apos;s Start</div>
  </div>
);
export default PageTop;
