function reducer(state = { count: 0 }, action) {
  switch (action.type) {
    case "COUNTER_INCREMENT":
      return { ...state, count: state.count + 1 };
    case "COUNTER_DECREMENT":
      return { ...state, count: state.count - 1 };
  }
  return state;
}
export default reducer;
