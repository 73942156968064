import "./PageCategorySelect.css";
const PageCategorySelect = () => (
  <div className="page-Ctegoryselect">
    <div className="container">
      <h1>
        <i className="ion ionicons icon ion-ios-arrow-back"></i>カテゴリーの選択
      </h1>
      <div className="ev-dtpage">
        <div className="ev_productsbody">
          <div>
            <ul>
              <li className="big_category">
                {/* <img src="./sc.jpg"> */}
                <div className="name_big">スーツケース</div>
                <i className="ion ionicons icon ion-ios-arrow-up"></i>
              </li>
              <ul className="small_category">
                <li>
                  <div className="name_small">スーツケースＳ</div>
                  <hr />
                </li>
                <li>
                  <div className="name_small">スーツケースＭ</div>
                  <hr />
                </li>
                <li>
                  <div className="name_small">スーツケースＬ</div>
                  <hr />
                </li>
                <li>
                  <div className="name_small">スーツケースLL</div>
                  <hr />
                </li>
                <li>
                  <div className="name_small">スーツケースベルト</div>
                  <hr />
                </li>
                <li>
                  <div className="name_small">ネームタグ</div>
                </li>
              </ul>
              <li className="big_category">
                {/* <img src="./sc7.jpg" /> */}
                <div className="name_big">バッグ</div>
                <i className="ion ionicons icon ion-ios-arrow-down"></i>
              </li>
              <hr />
              <li className="big_category">
                {/* <img src="./sc8.jpg" /> */}
                <div className="name_big">ポーチ</div>
                <i className="ion ionicons icon ion-ios-arrow-down"></i>
              </li>
              <hr />
              <li className="big_category">
                {/* <img src="./sc9.jpg" /> */}
                <div className="name_big">海外用電化製品</div>
                <i className="ion ionicons icon ion-ios-arrow-down"></i>
              </li>
              <hr />
              <li className="big_category">
                {/* <img src="./plug.png" /> */}
                <div className="name_big">電圧変換器</div>
                <i className="ion ionicons icon ion-ios-arrow-down"></i>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default PageCategorySelect;
