import "./PageDateSelect.css";
import { useCalendar } from "components/organisms/Calendar";
const PageDateSelect = () => {
  const {
    year,
    month,
    switchToPrevMonth,
    switchToNextMonth,
    dates,
    selecting,
  } = useCalendar();
  return (
    <div className="page-date-select">
      <div className="container">
        <h1>
          <i className="ion ionicons icon ion-ios-arrow-back"></i>日程の選択
        </h1>
        <div className="calendar-ym-box">
          <span className="calendar-ym-area" onClick={switchToPrevMonth}>
            ＜&nbsp;前月
          </span>
          <span className="calendar-ym-area">
            {year}年{month}月
          </span>
          <span className="calendar-ym-area" onClick={switchToNextMonth}>
            翌月&nbsp;＞
          </span>
        </div>
        <table className="calendar">
          <thead>
            <tr>
              <th>日</th>
              <th>月</th>
              <th>火</th>
              <th>水</th>
              <th>木</th>
              <th>金</th>
              <th>土</th>
            </tr>
          </thead>
          <tbody>
            {dates.map((week, weekIndex) => (
              <tr key={"week" + weekIndex}>
                {week.map(
                  (
                    { date, onClick, isThisMonth, inRange, isStart, isEnd },
                    dayIndex
                  ) => (
                    <td
                      onClick={onClick}
                      key={"day" + (weekIndex * 7 + dayIndex)}
                    >
                      <div
                        className={(
                          (isThisMonth ? " date-is-this-month" : "") +
                          (inRange ? " date-in-range" : "") +
                          (isStart ? " date-is-start" : "") +
                          (isEnd ? " date-is-end" : "") +
                          (inRange && selecting ? " date-is-selecting" : "")
                        ).slice(1)}
                      >
                        {date}
                      </div>
                    </td>
                  )
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default PageDateSelect;
